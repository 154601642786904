import {
  Avatar,
  AvatarGroup,
  ButtonProps,
  Checkbox,
  List,
  ListItem,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Profile } from "../../../constants/data-types";
import { RootState } from "../../../redux/store";

interface UbicoAvatarProps extends ButtonProps {
  selectedProfiles: Profile[];
  onSelectProfile(profile: Profile): void;
  showMax?: number;
}

const StyledAvatar = styled(Avatar)(() => ({
  // Button style
}));

const UbicoProfileAvatarSelector: React.FC<UbicoAvatarProps> = (props) => {
  const { selectedProfiles, onSelectProfile, showMax = 10 } = props;
  const theme = useTheme();

  const [hoveredProfile, setHoveredProfile] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const team = useSelector((state: RootState) => state.team);

  const selectedProfileIds = selectedProfiles.map((p) => p.id);

  return (
    <AvatarGroup max={showMax}>
      {team.slice(0, showMax - 1).map((profile: Profile) => {
        const isHovered = hoveredProfile?.id === profile.id;
        const isSelected = selectedProfileIds.includes(profile.id);
        return (
          <Tooltip
            key={profile.id}
            title={profile.user?.first_name + " " + profile.user?.last_name}
          >
            <StyledAvatar
              alt={profile.user?.first_name + " " + profile.user?.last_name}
              src={profile.avatar}
              sx={{
                width: 30,
                height: 30,
                zIndex:
                  isSelected || isHovered ? theme.zIndex.appBar : "inherit",
                cursor: "pointer",
              }}
              style={{
                outline:
                  isSelected && `2px solid ${theme.palette.primary.main}`,
                outlineOffset: 0.2,
              }}
              imgProps={{ referrerPolicy: "no-referrer" }}
              onClick={() => onSelectProfile(profile)}
              onMouseEnter={() => setHoveredProfile(profile)}
              onMouseLeave={() => setHoveredProfile(null)}
            >
              <Typography sx={{ fontSize: 15 }}>
                {profile?.user?.first_name?.[0] || ""}
                {profile?.user?.last_name?.[0] || ""}
              </Typography>
            </StyledAvatar>
          </Tooltip>
        );
      })}
      {team.length > showMax && (
        <>
          <StyledAvatar
            sx={{ width: 30, height: 30 }}
            onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
          >
            <Typography variant="body2">+{team.length - showMax}</Typography>
          </StyledAvatar>
          <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onMouseLeave={() => setAnchorEl(null)}
            placement="bottom"
          >
            <Paper sx={{ p: 2 }}>
              <List disablePadding sx={{ maxHeight: 400, overflow: "scroll" }}>
                {team.slice(showMax, team.length).map((profile: Profile) => (
                  <ListItem disablePadding key={profile.id}>
                    <Stack
                      direction={"row"}
                      spacing={theme.spacing(1)}
                      alignItems={"center"}
                    >
                      <Checkbox
                        checked={selectedProfileIds.includes(profile.id)}
                        onChange={() => onSelectProfile(profile)}
                      />
                      <StyledAvatar
                        alt={
                          profile.user?.first_name +
                          " " +
                          profile.user?.last_name
                        }
                        src={profile.avatar}
                        sx={{ width: 30, height: 30, cursor: "pointer" }}
                      />
                      <Typography>
                        {profile.user?.first_name} {profile.user?.last_name}
                      </Typography>
                    </Stack>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Popper>
        </>
      )}
    </AvatarGroup>
  );
};

export default UbicoProfileAvatarSelector;
