import { CameraAlt, Close, Image as ImageIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { alpha, Box, Container, DialogActions, DialogContent, IconButton, Slider, Stack, Typography, useTheme } from "@mui/material";
import { FC, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from "react-redux";
import UbicoDialog from "../../../../../../components/custom/Dialog";
import UbicoButton from "../../../../../../components/custom/buttons/Button";
import { Profile } from "../../../../../../constants/data-types";
import { setProfile } from "../../../../../../redux/reducers/profileReducer";
import { RootState } from "../../../../../../redux/store";
import ProfileService from "../../../../../../services/profileService";

const PREVIEW_SIZE = 200

interface AvatarUploadProps {
  open: boolean
  close(): void
}

const AvatarUpload: FC<AvatarUploadProps> = (props): ReactElement => {
  const { open, close } = props

  const dispatch = useDispatch()
  const theme = useTheme()

  const profile: Profile = useSelector((state: RootState) => state.profile)

  const [originalImage, setOriginalImage] = useState(null);
  const [scale, setScale] = useState(1);

  const editor = useRef(null);

  const onDrop = useCallback((acceptedFiles: any[]) => {
    if(acceptedFiles?.length === 1) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        setOriginalImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }, [])

  const handleScaleChange = (newValue) => {
    setScale(newValue);
  };

  const saveImage = async () => {
    if (editor) {
      const canvasScaled = editor.current.getImageScaledToCanvas()
      const date = new Date().toISOString().slice(0, 10)

      // Convert the canvas to a data URL and then to a Blob
      canvasScaled.toBlob(async (blob) => {
        const file = new File([blob], `${profile?.id}-${date}-avatar.jpg`, { type: "image/jpeg" });

        const { error, data } = await ProfileService.updateProfileAvatar(
          profile?.id,
          file
        )

        if (data && !error) {
          dispatch(setProfile(data))
          close()
        }
      });
    }
  };

  const resetImage = () => {
    setOriginalImage(null)
  }

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open: openFileUpload
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': []
    },
    maxSize: 15000000,
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
  })

  useEffect(() => {
    if(!open) setOriginalImage(null)
  }, [open])

  return (
    <UbicoDialog title="Update Profile Picture" open={open} handleClose={close}>
      <DialogContent dividers>
        {originalImage ?
            <Stack height={'100%'} justifyContent={'center'} alignItems={'center'} spacing={theme.spacing(2)}>
              <Box position="relative" display="inline-block">
                <IconButton
                  size="medium"
                  sx={{
                    marginLeft: 0,
                    position: 'absolute',
                    top: 4,
                    right: 4,
                    zIndex: 1, // Ensure it stays above the AvatarEditor
                    padding: '2px',
                  }}
                  onClick={resetImage}
                >
                  <Close sx={{fontSize: 15}}/>
                </IconButton>
                <AvatarEditor
                  ref={editor}
                  borderRadius={1000} scale={scale} width={250} height={250} image={originalImage}
                />
              </Box>
              <Stack direction={'row'} spacing={theme.spacing(1)} width={'100%'} alignItems={'center'}>
                <ImageIcon fontSize="small"/>
                <Slider
                  value={scale}
                  min={0.1}
                  max={2}
                  step={0.01}
                  onChange={(_, value: number) => handleScaleChange(value)}
                />
                <ImageIcon fontSize="medium"/>
              </Stack>
            </Stack>
          :
            <Stack height={'100%'} justifyContent={'center'} alignItems={'center'} spacing={theme.spacing(2)}>
              <Container {...getRootProps({isFocused, isDragAccept, isDragReject})}
                sx={{
                  width: PREVIEW_SIZE, height: PREVIEW_SIZE,
                  padding: theme.spacing(2),
                  borderWidth: 1.5,
                  borderRadius: '100%',
                  borderColor: (
                    isDragReject ? theme.palette.error.main :
                    isDragAccept ? theme.palette.primary.main :
                    theme.palette.text.primary
                  ),
                  borderStyle: 'dashed',
                  backgroundColor: (
                    isDragReject ? alpha(theme.palette.error.main, 0.2) :
                    isDragAccept ? alpha(theme.palette.primary.main, 0.2) :
                    theme.palette.divider
                  ),
                  outline: 'none',
                  transition: 'background-color .24s ease-in-out, border .24s ease-in-out'
                }}
              >
                <input {...getInputProps()} />
                <Stack height={'100%'} width={'100%'} alignItems={'center'} justifyContent={'center'} spacing={theme.spacing(2)}>
                  <CameraAlt sx={{fontSize: 40}}/>
                  <Typography textAlign={'center'} variant="caption">Drag and drop your images here</Typography>
                </Stack>
              </Container>
              <Typography>Or</Typography>
              <UbicoButton variant="contained" onClick={openFileUpload}>Upload file</UbicoButton>
            </Stack>
        }
      </DialogContent>
      <DialogActions>
        {originalImage &&
          <Stack direction={'row'} spacing={theme.spacing(2)} alignItems={'center'} justifyContent={'flex-end'} width={'100%'}>
            <UbicoButton onClick={close}>Cancel</UbicoButton>
            <LoadingButton variant="contained" onClick={saveImage}>Upload</LoadingButton>
          </Stack>
        }
      </DialogActions>
    </UbicoDialog>
  )
}

export default AvatarUpload
