import { Stack, useTheme } from "@mui/material";
import { axisClasses, BarChart, useDrawingArea } from "@mui/x-charts";
import { FC, ReactElement } from "react";
import { CreditUsage } from "../../../../../../../constants/data-types";

const LoadingOverlay = () => {
  const theme = useTheme();
  const { left, width, height } = useDrawingArea();

  const centerX = left + width / 2;
  const centerY = height / 2; // Assuming you want to center vertically within the chart area

  return (
    <g transform={`translate(${centerX}, ${centerY})`}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="50"
          cy="50"
          r="45"
          fill="none"
          stroke="#ccc"
          strokeWidth="10"
        />
        <circle
          cx="50"
          cy="50"
          r="45"
          fill="none"
          stroke={theme.palette.primary.main}
          strokeWidth="10"
          strokeDasharray="150"
          strokeDashoffset="0"
        >
          <animate
            attributeName="stroke-dashoffset"
            dur="600ms"
            repeatCount="indefinite"
            from="0"
            to="360"
          />
        </circle>
      </svg>
    </g>
  );
};

interface CreditPeriodUsageProps {
  creditUsage: CreditUsage[];
  loading: boolean;
}

const CreditPeriodUsage: FC<CreditPeriodUsageProps> = (props): ReactElement => {
  const { creditUsage, loading } = props;
  const theme = useTheme();

  const totalSum = creditUsage?.map((i) => i.count)?.reduce((a, b) => a + b, 0);

  return (
    <Stack>
      <BarChart
        height={200}
        borderRadius={5}
        colors={[theme.palette.primary.main]}
        dataset={totalSum > 0 ? creditUsage || [] : []}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "date",
            tickLabelInterval: () => null
          },
        ]}
        yAxis={[
          {
            scaleType: "linear",
            dataKey: "count",
            tickLabelPlacement: "tick",
            tickPlacement: "middle",
            tickLabelInterval: (_, index) =>
              index !== 0 &&
              index !== creditUsage?.length - 1 &&
              index % 1 === 0,
          },
        ]}
        bottomAxis={{ disableTicks: true }}
        leftAxis={{ disableTicks: true, disableLine: true }}
        series={[{ dataKey: "count" }]}
        loading={loading}
        slots={{
          loadingOverlay: LoadingOverlay,
        }}
        sx={{
          [`.${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
            transform: "rotateZ(-45deg) translate(0px, 20px)"
          }
        }}
      />
    </Stack>
  );
};

export default CreditPeriodUsage;
