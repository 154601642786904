import { Stack, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";

import {
  ButtonGroup
} from "@mui/material";
import { PickersShortcutsItem } from "@mui/x-date-pickers";
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import UbicoButton from "../../../components/custom/buttons/Button";
import { SegmentStatsFrequencies } from "../../../pages/overview/constants/segment-stats";
import { MONTH_DAYS, THREE_MONTH_DAYS, WEEK_DAYS, YEAR_DAYS } from "../../../utils/date-utils";

const defaultShortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: "Today",
    getValue: () => {
      const today = dayjs();
      return [today, today];
    },
  },
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today];
    },
  },
  {
    label: "Last Month",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(1, "month"), today];
    },
  },
  { label: "All Time", getValue: () => [null, null] },
];

interface DateRangeFilterProps {
  startDate: string,
  endDate: string,
  frequency: SegmentStatsFrequencies,
  onRangeChange(startDate: string, endDate: string, frequency: SegmentStatsFrequencies): void
  onFrequencyChange(frequency: SegmentStatsFrequencies): void
  shortcutItems?: PickersShortcutsItem<DateRange<Dayjs>>[]
  disableFuture?: boolean
  rangeFieldWidth?: number
}

const DateRangeFilter: FC<DateRangeFilterProps> = (props) => {
  const theme = useTheme()

  const {
    startDate, endDate, frequency, onRangeChange, onFrequencyChange,
    disableFuture = false, shortcutItems = defaultShortcutsItems,
    rangeFieldWidth = null
  } = props

  const [isAllTime, setIsAllTime] = useState(false)
  const [disableDays, setDisableDays] = useState(false);
  const [disableWeeks, setDisableWeeks] = useState(false);
  const [disableMonths, setDisableMonths] = useState(false);

  const dateRange: DateRange<Dayjs> = [
    dayjs(startDate),
    dayjs(endDate)
  ];

  const onDateRangeChange = (range: DateRange<Dayjs>) => {
    const newFrequency = validateDates(range);
    const startDate = range[0] ? range[0].toISOString().slice(0, 10) : ""
    const endDate = range[1] ? range[1].toISOString().slice(0, 10) : ""

    onRangeChange(startDate, endDate, newFrequency)
  };

  const validateDates = (range): SegmentStatsFrequencies | null => {
    // If all time selected
    if (isAllTime || range.some((r) => r === null)) {
      setIsAllTime(true);
      setDisableMonths(false);
      setDisableWeeks(false);
      setDisableDays(true);
      if (frequency === SegmentStatsFrequencies.Daily) {
        return SegmentStatsFrequencies.Weekly;
      }
      return frequency
    }

    const days = range[1].diff(range[0], "days");

    if (days <= WEEK_DAYS) {
      setDisableDays(false);
      setDisableMonths(true);
      setDisableWeeks(true);
      return SegmentStatsFrequencies.Daily;
    } else if (days <= MONTH_DAYS) {
      setDisableDays(false);
      setDisableWeeks(false);
      setDisableMonths(true);
      if (frequency === SegmentStatsFrequencies.Monthly) {
        return SegmentStatsFrequencies.Weekly;
      }
    } else if (days > THREE_MONTH_DAYS && days <= YEAR_DAYS) {
      setDisableWeeks(false);
      setDisableMonths(false);
      setDisableDays(true);
      if (frequency === SegmentStatsFrequencies.Daily) {
        return SegmentStatsFrequencies.Weekly;
      }
    } else if (days > YEAR_DAYS) {
      setDisableWeeks(true);
      setDisableDays(true);
      setDisableMonths(false);
      return SegmentStatsFrequencies.Monthly;
    } else {
      setDisableDays(false);
      setDisableWeeks(false);
      setDisableMonths(false);
    }
    return frequency
  };

  useEffect(() => {
    validateDates(dateRange)
  }, [dateRange])

  return (
    <Stack width={'100%'} direction={'row'} spacing={theme.spacing(2)} alignItems={'center'}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          slotProps={{
            textField: {
              size: "small",
              inputProps: { style: { fontSize: 13 } },
              label: isAllTime ? "All Time" : "Date range",
              InputLabelProps: { style: { fontSize: 13, margin: 0 } },
            },
            shortcuts: { items: shortcutItems },
            actionBar: { actions: [] },
          }}
          value={!isAllTime ? dateRange : [null, null]}
          onAccept={(newValue: DateRange<Dayjs>) => onDateRangeChange(newValue)}
          sx={{ width: rangeFieldWidth || "100%" }}
          slots={{ field: SingleInputDateRangeField }}
          disableFuture={disableFuture}
        />
      </LocalizationProvider>
      <ButtonGroup sx={{ height: 30 }}>
        <UbicoButton
          variant={
            frequency === SegmentStatsFrequencies.Daily
              ? "contained"
              : "outlined"
          }
          onClick={() => onFrequencyChange(SegmentStatsFrequencies.Daily)}
          disabled={disableDays}
        >
          Daily
        </UbicoButton>
        <UbicoButton
          variant={
            frequency === SegmentStatsFrequencies.Weekly
              ? "contained"
              : "outlined"
          }
          onClick={() => onFrequencyChange(SegmentStatsFrequencies.Weekly)}
          disabled={disableWeeks}
        >
          Weekly
        </UbicoButton>
        <UbicoButton
          variant={
            frequency === SegmentStatsFrequencies.Monthly
              ? "contained"
              : "outlined"
          }
          onClick={() => onFrequencyChange(SegmentStatsFrequencies.Monthly)}
          disabled={disableMonths}
        >
          Monthly
        </UbicoButton>
      </ButtonGroup>
    </Stack>
  )
}

export default DateRangeFilter
