import { AllInbox } from "@mui/icons-material";
import { Icon } from "@mui/material";
import ComingSoonFeatureChip from "../components/custom/chips/ComingSoonFeatureChip";
import { COLLABORATOR_ROLE } from "../constants/user-roles";
import NotFound from "../pages/NotFound";
import { Route } from "./routeInterface";

export const unifiedInboxRoutes: Array<Route> = [
  {
    key: "unified-inbox-route",
    label: "Inbox",
    path: "inbox",
    enabled: false,
    component: NotFound,
    isTopTab: true,
    order: 0,
    tabInfo: {
      id: "inbox",
      startIcon: <Icon component={AllInbox} fontSize="small" />,
      endIcon: <ComingSoonFeatureChip />,
      alwaysShowEndIcon: true,
    },
    leastRole: COLLABORATOR_ROLE,
  }
];
