import { Alert, Box, Chip, Stack, Typography, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import CancellationImage from "../../../../../../assets/images/techny-business-analytics-on-tablet-screen.gif";
import UbicoButton from "../../../../../../components/custom/buttons/Button";
import {
  Account,
  AccountUsage,
  Profile,
} from "../../../../../../constants/data-types";
import { useSubscriptionCancellation } from "../../../../../../hooks/useSubscriptionCancellation";
import { RootState } from "../../../../../../redux/store";
import { CancellationFlowSteps } from "../../../constants/account-billing";

const SubscriptionCancelOverview: FC = (): ReactElement => {
  const { setCurrentStep } = useSubscriptionCancellation();

  const theme = useTheme();

  const account: Account = useSelector((state: RootState) => state.account);
  const accountUsage: AccountUsage = useSelector(
    (state: RootState) => state.accountUsage,
  );
  const profile: Profile = useSelector((state: RootState) => state.profile);

  const accountCreatedDate = account?.created_at
    ? new Date(account?.created_at).toDateString()
    : null;

  return (
    <Stack>
      <Stack spacing={theme.spacing(2)}>
        {accountCreatedDate ? (
          <Typography>
            {profile?.user?.first_name}, you have been with us since{" "}
            {accountCreatedDate}!
          </Typography>
        ) : (
          <Typography>
            {profile?.user?.first_name}, we are glad to have you on a journey
            with Ubico.
          </Typography>
        )}
        <Typography>Let's break down how Ubico has helped you...</Typography>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack spacing={theme.spacing(1)}>
            <Stack direction={"row"} spacing={theme.spacing(1)}>
              <Typography fontWeight={"bolder"}>
                Pipeline Value
              </Typography>
              <Chip
                size="small"
                variant="filled"
                color="primary"
                label={
                  <Typography fontWeight={"bolder"} variant="body2">
                    $ <CountUp end={accountUsage?.uav} />
                  </Typography>
                }
              />
            </Stack>
            <Stack direction={"row"} spacing={theme.spacing(1)}>
              <CountUp
                end={accountUsage?.generated_people_count}
                style={{ fontWeight: "bolder" }}
              />
              <Typography>Generated People</Typography>
            </Stack>
            <Stack direction={"row"} spacing={theme.spacing(1)}>
              <CountUp
                end={accountUsage?.campaigns_count}
                style={{ fontWeight: "bolder" }}
              />
              <Typography>Campaigns</Typography>
            </Stack>
            <Stack direction={"row"} spacing={theme.spacing(1)}>
              <CountUp
                end={accountUsage?.segments_count}
                style={{ fontWeight: "bolder" }}
              />
              <Typography>Segments</Typography>
            </Stack>
            <Stack direction={"row"} spacing={theme.spacing(1)}>
              <CountUp
                end={accountUsage?.emails_sent_count}
                style={{ fontWeight: "bolder" }}
              />
              <Typography>Emails sent</Typography>
            </Stack>
            <Stack direction={"row"} spacing={theme.spacing(1)}>
              <CountUp
                end={accountUsage?.conversations_count}
                style={{ fontWeight: "bolder" }}
              />
              <Typography>Conversations</Typography>
            </Stack>
          </Stack>
          <Box
            component="img"
            src={CancellationImage}
            sx={{
              maxHeight: 250,
              maxWidth: 250,
              width: "auto",
              height: "auto",
              display: "block",
            }}
          />
        </Stack>
        <Alert variant="outlined" severity="error">
          By cancelling your subscription, you will not be able to continue
          running Campaigns and add People to Ubico
        </Alert>
        <UbicoButton
          onClick={() => setCurrentStep(CancellationFlowSteps.Deal)}
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          color="error"
        >
          I want to cancel
        </UbicoButton>
      </Stack>
    </Stack>
  );
};

export default SubscriptionCancelOverview;
