import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Profile } from "../../constants/data-types";
import { COLLABORATOR_ROLE } from "../../constants/user-roles";

const initialState: Profile = {
  id: -1,
  picture: "",
  user: {
    first_name: "",
    last_name: "",
    email: "",
    verified: true,
  },
  created_at: null,
  last_login: null,
  user_role: COLLABORATOR_ROLE,
  workspaces: null,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<Profile>) => {
      (state.id = action.payload.id),
        (state.user = action.payload.user),
        (state.avatar = action.payload.avatar),
        (state.user_role = action.payload.user_role),
        (state.workspaces = action.payload.workspaces);
    },
  },
});

export const { setProfile } = profileSlice.actions;

export default profileSlice.reducer;
