import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Drawer,
  IconButton,
  Link,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as UbicoLogo } from "../assets/logos/ubico.svg";
import { NAVBAR_LEFT_ELEVATION } from "../components/navbar/left/NavbarLeft";
import { HOME_ROUTE } from "../routes/appRoutes";

const NotFound: FC = (): ReactElement => {
  const theme = useTheme();
  const navigate = useNavigate();

  const goPreviousPage = () => {
    navigate(-1);
  };

  const goToOverview = () => {
    navigate(HOME_ROUTE);
  };

  return (
    <Drawer
      open
      sx={{ zIndex: theme.zIndex.drawer + NAVBAR_LEFT_ELEVATION + 1 }}
      PaperProps={{ sx: { width: "100vw" } }}
    >
      <Stack alignItems={"flex-start"} padding={theme.spacing(3)}>
        <IconButton onClick={goPreviousPage}>
          <ArrowBack />
        </IconButton>
        <Box
          width={"100%"}
          height={"80vh"}
          display={"flex"}
          justifyContent={"center"}
        >
          <Stack
            maxWidth={"50%"}
            height={"100%"}
            alignItems={"center"}
            direction={"row"}
            spacing={theme.spacing(5)}
          >
            <SvgIcon
              component={UbicoLogo}
              sx={{
                color: theme.palette.text.primary,
                height: 150,
                width: 150,
              }}
              inheritViewBox
            />
            <Stack spacing={theme.spacing(2)}>
              <Typography variant="h4" gutterBottom>
                Page Not Found
              </Typography>
              <Typography variant="body1">
                We apologize, but the page you're looking for doesn't exist.
                Return to the{" "}
                <Link sx={{ cursor: "pointer" }} onClick={goPreviousPage}>
                  previous page
                </Link>{" "}
                or go back to your{" "}
                <Link sx={{ cursor: "pointer" }} onClick={goToOverview}>
                  dashboard
                </Link>
                .
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Drawer>
  );
};

export default NotFound;
